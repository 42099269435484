/** @jsx jsx */
import { jsx } from 'theme-ui';
import HeaderWithImage from '../components/Common/HeaderWithImage';
import WhatWeDo from '../components/Home/WhatWeDo';
import FeaturedSection from '../components/Home/FeaturedSection';
import EmbryoAdoption from '../components/Home/EmbryoAdoption';
import SignUp from '../components/Home/SignUp';
import Apply from '../components/Common/Apply';
import Layout from '../components/Layout/index';
import { Seo } from '../components/Seo';

const Worldpage = () => {
  return (
    <Layout>
      <Seo title="NEDC World" description="The non-profit National Embryo Donation Center helps families using in vitro fertilization (IVF) manage their embryos and their options" />
      <div>
        <HeaderWithImage
          title={'National Embryo Donation Center'}
          description={'Giving Life. Giving Hope.'}
          image={'/images/Konsults_with_Keenan_HomePage.png'}
          imageLink={'https://www.embryodonation.org/free-consultations'}
          //imageLink={'http://localhost:8000/free-consultations'}
          //video={'https://www.youtube.com/watch?v=z4xKC7CpOZ8'}
          //background={''}
          //background={'/images/header/homepage.png'}
          //background={'/images/header/chayene-rafaela-nGwkIZFelko-unsplash.png'}
        />
        <WhatWeDo />
        <FeaturedSection />
        <section
          sx={{
            backgroundImage: `url('/images/background3.jpg')`,
            backgroundSize: 'cover',
          }}
        >
          <EmbryoAdoption />
          <SignUp />
        </section>
        <Apply />
      </div>
    </Layout>
  );
};

export default Worldpage;
